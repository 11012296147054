import styled from 'styled-components'

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 100%; */
    max-width: 1366px;
    margin: 3rem auto;
`




