import { SectionTitle } from "../ProfileSection/ProfileSection.styles";

import Availability from '../Availability/Availability';

const SectionAvailability = () => {


    return(
        <>
            <SectionTitle>Configuración de Disponibilidad</SectionTitle>
            <Availability />
            
        </>
    )
}

export default SectionAvailability;